import styled, { css, CSSProperties } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useHistory, useLocation } from "react-router-dom"
import { memo, useEffect } from "react"
import { allAnimations } from "../effects/Observers"
import { useAnimations } from "../effects/Effects"
import HerrajesIntro from "./HerrajesIntro"

const InfoContainer = styled.div<{ type: "pvc" | "aluminio" | "herrajes" }>`
    width: 100%;
    display: grid;
    grid-template-columns:  repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    position: relative;
    /* max-width: 1500px;
    margin-bottom: 10vh; */
    width: 100vw;

    @media ${device.xl} {
        width: 100vw;
    }

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 7vh;
    }

    ${({ type }) => type === 'herrajes' && css`
        margin-top: 20vh;
        max-width: unset;
        
        @media ${device.lg} {
            margin-top: 10vh;
        }

        @media ${device.md} {
            margin-top: 5vh;
        }

    `}
`

const Card = styled.div<{ textPosition: 'left' | 'right', type: "pvc" | "aluminio" | "herrajes" }>`
    min-height: 50vh;
    padding: 0vh 5vw 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    background-color: #111;

    ${({ textPosition }) => textPosition === 'left' ? css`
        grid-column: 1 / 3;

        @media ${device.md} {
            grid-column: 1 / 4;
        }
    `: css`
        grid-column: 3 / 5;

        @media ${device.md} {
            grid-column: 2 / 5;
        }
    `}

    ${({ type }) => type === 'herrajes' && css`
        grid-column: 1 / 5;

        @media ${device.md} {
            grid-column: 1 / 4;
        }
    `}

    @media ${device.md} {
        min-height: unset;
        padding: 5vh 5vw 10vh;

    }

    @media ${device.sm} {
        padding: 5vh 5vw 7vh;
        
    }

    @media ${device.xs} { 
        
    }
`

const Title = styled.h2`
    width: 100%;
    font-size: 42px;
    color: #fff;
    
    @media ${device.md} {
        font-size: 34px;
    }

    @media ${device.sm} {
        font-size: 28px;
        text-align: center !important;
    }

    @media ${device.xs} {
        font-size: 24px;
    }

`

const TextInfo = styled.p`
    width: 100%;
    color: #fff;
    line-height: 25px;
    margin: 30px 0px;
    max-width: 450px;
    z-index: 1;
    font-size: 20px;


    @media ${device.lg} {
        font-size: 18px;
    }
    
    @media ${device.md} {
        max-width: 80%;
        font-size: 16px;
    }

    @media ${device.sm} {
        max-width: unset;
        font-size: 14px;
        text-align: center !important;
    }
`

const MoreInfoButton = styled.button`

    @keyframes fillButton {
        0% {
            width: 0%;
        }
        100% {
            width: 100%
        }
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    background-color: white;
    border-radius: 50px;
    font-size: 20px;
    margin-top: 20px;
    overflow: hidden;

    img {
        width: 18px;
        height: 18px;
        margin: 2px 7px 0px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2px;
        height: 100%;
        width: 0%;
        background: rgba(0, 0, 0, 0.1);
    }

    &:hover {
        box-shadow: 0px 2px 6px 0px rgba(255, 255, 255, 0.5);
        transform: scale(1.2);

        &::before {
            animation: fillButton 0.5s ease-in-out forwards;
        }
    }

    @media ${device.sm} {
        font-size: 16px;
        align-self: center !important;
        margin-left: unset !important;

        img {
            width: 15px;
            height: 15px;
        }
    }

`

const Abertura01 = styled.img`
    height: 90%;
    width: auto;
    position: absolute;
    z-index: 3;
    left: 0px;
    right: 10%;
    margin: auto;
    transform: scaleX(-1);

    @media ${device.lg} {
        height: 70%;
        right: 10%;
    }

    @media ${device.md} {
        display: none;
    }
`

const Abertura02 = styled.img`
    height: 70%;
    width: auto;
    position: absolute;
    z-index: 3;
    left: 0px;
    right: 60%;
    bottom: 0px;
    top: 20%;
    margin: auto;

    @media ${device.lg} {
        height: 60%;
    }

    @media ${device.md} {
        display: none;
    }
`

const Abertura03 = styled.img`
    height: 90%;
    width: auto;
    position: absolute;
    z-index: 2;
    left: 25%;
    right: 0px;
    margin: auto;

    @media ${device.xl} {
        left: 30%;
    }

    @media ${device.lg} {
        height: 80%;
        left: 42%;
    }

    @media ${device.md} {
        display: none;
    }
`

const Abertura04 = styled.img`
    height: 120px;
    width: auto;
    position: absolute;
    z-index: 2;
    left: 0px;
    right: 5%;
    top: 0px;
    margin: auto;

    @media ${device.xl} {
        right: 15%;
    }

    @media ${device.lg} {
        height: 100px;
        right: 0px;
        left: 20%;
        top: 8%;
    }

    @media ${device.md} {
        display: none;
    }
`

const Abertura05 = styled.img`
    height: 50px;
    width: auto;
    position: absolute;
    z-index: 2;
    left: 35%;
    right: 0px;
    top: 5%;
    margin: auto;

    @media ${device.xl} {
        left: 25%;
    }

    @media ${device.lg} {
        height: 30px;
        left: 60%;
        right: 0px;
        top: 14%;
    }

    @media ${device.md} {
        display: none;
    }
`

const Abertura06 = styled.img`
    height: 70%;
    width: auto;
    position: absolute;
    z-index: 2;
    left: 0px;
    right: 0px;
    top: 20%;
    margin: auto;

    @media ${device.xl} {
        right: 10%;

    }

    @media ${device.lg} {
        height: 60%;
        top: 25%;
        right: -20%;
    }

    @media ${device.md} {
        display: none;
    }
`

const Abertura07 = styled.img`
    height: 60%;
    width: auto;
    position: absolute;
    z-index: 2;
    left: 0px;
    right: -60%;
    top: 0px;
    margin: auto;
    transform: rotate(20deg);
    

    @media ${device.xl} {
        right: -50%;
        top: 5%;
    }

    @media ${device.lg} {
        height: 50%;
        top: 10%;
        right: -75%;

    }

    @media ${device.md} {
        display: none;
    }
`

const Abertura08 = styled.img`
    height: 40%;
    width: auto;
    position: absolute;
    z-index: 2;
    right: -10vw;
    bottom: 0px;
    transform: scaleX(-1);

    @media ${device.lg} {
        height: 30%;
    }

    @media ${device.md} {
        display: none;
    }
`

const WaveDiv = styled.div`
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 50%; /* Ocupa 1/4 del contenedor padre */
  background-color: transparent;
  overflow: hidden;
  line-height: 0;
  
  /* SVG de ondas */
  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100%;
  }

  /* Color de las ondas */
  .wave {
    fill: red;
  }

  
  @media ${device.sm} {
    bottom: -4vh;

    svg {

        width: 500%;
    }
    }
`;

const Scroll = styled.div`
    height: 1px;
    width: 1px;
    position: absolute;
    z-index: 2;
    left: 0px;
    right: 0px;
    top: -1000px;
    margin: auto;

`


const InfoContainerV2 = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns:  repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 10vh;

    @media ${device.xl} {
        width: 100vw;
    }

    @media ${device.lg} {
        margin-top: 10vh;

    }

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 7vh;
    }
`


const ImageContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    background-color: red;

    grid-column: 1 / 3;

    @media ${device.md} {
        display: none;
    }
`

const CardV2 = styled.div`
    height: fit-content;
    padding: 20vh 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    background-color: #fff;

    grid-column: 3 / 5;

    @media ${device.lg} {
        padding: 10vh 10vw;
    }

    @media ${device.md} {
        grid-column: 1 / 5;
    }

`


interface Props {
    type: 'pvc' | 'aluminio' | 'herrajes';
    style?: CSSProperties;
    textPosition: 'left' | 'right';
    goTo: '/productos' | '/productos/aluminio' | '/productos/pvc' | '/productos/herrajes';
}

const ProductItemComponent = ({ type, textPosition, goTo, style }: Props) => {

    const history = useHistory();

    const location = useLocation();

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname])

    const {
        handleClickGoProductsInstant,
        handleClickGoProductInstant
    } = useAnimations();

    const handleClickGoTo = () => {

        history.push(goTo);

        setTimeout(() => {
            handleClickGoProductInstant()
            handleClickGoProductsInstant()
        }, 10);

    }

    const setTitle = () => {

        switch (type) {
            case 'pvc':
                return ['Aberturas de', 'PVC REHAU']
            case 'aluminio':
                return ['Aberturas de', 'ALUMINIO HYDRO']
            case 'herrajes':
                return ['Nuestros herrajes', 'ROTO FRANK']
        }
    }

    const setDescription = () => {

        switch (type) {
            case 'pvc':
                return `Representan una solución avanzada en el ámbito de 
                la arquitectura y la construcción combinando diseño moderno 
                con tecnología de vanguardia. Su composición asegura un aislamiento 
                térmico y acústico superior, optimizando el confort interior y 
                reduciendo significativamente el consumo energético.`
            case 'aluminio':
                return `Representan una solución avanzada en el ambito de 
                la arquitectura y la construccion combinando diseño moderno 
                con tecnología de vanguardia. Su composición asegura un aislamiento 
                térmico y acústico superior, optimizando el confort interior y 
                reduciendo significativamente el consumo energético.`
            case 'herrajes':
                return `Son sinónimo de precisión y calidad alemana en sistemas 
                de apertura, cierre para ventana y puertas. Diseñados con tecnología 
                avanzada, estos herrajes garantizan una funcionalidad impecable, 
                seguridad superior y durabilidad en el tiempo. Su ingeniería innovadora 
                permite una facil operación y ajuste, ofreciendo soluciones versátiles 
                para una amplia configuración de ventanas y puertas. Además, los herrajes 
                Roto destacan por su capacidad de mejorar la eficiencia energética y el 
                confort, alineandose con las más altas exigencias en diseño y rendimiento arquitectónico.`
        }
    }

    return (

        <>

            <InfoContainer
                className="utils__fade-in-fast-top"
                style={{ ...style }}
                type={type}
            >

                <Scroll id="product" />


                {textPosition === 'right' && (
                    <>

                        <Abertura01
                            src={require('../images/ha62.png')}
                            alt="abertura"
                        />

                        <Abertura02
                            src={require('../images/ha110.png')}
                            alt="abertura"
                        />

                    </>
                )}


                <Card textPosition={textPosition} type={type}
                    style={{ backgroundColor: type === 'herrajes' ? 'transparent' : undefined }}
                >

                    <Title
                        className="utils__fade-in-fast-top"
                        style={{
                            textAlign: textPosition === 'left' ? 'start' : 'end',
                            color: type === 'herrajes' ? '#111' : undefined
                        }}
                    >
                        {setTitle()[0]}<br />{setTitle()[1]}
                    </Title>

                    <TextInfo
                        className="utils__fade-in-fast-top"
                        style={{
                            textAlign: textPosition === 'left' ? 'start' : 'end',
                            alignSelf: textPosition === 'left' ? 'start' : 'end',
                            color: type === 'herrajes' ? '#111' : undefined,
                            paddingBottom: type === 'herrajes' ? '15%' : undefined,

                        }}
                    >
                        {setDescription()}
                    </TextInfo>

                    {/* <MoreInfoButton
                        onClick={async () => handleClickGoTo()}
                        className="utils__fade-in-fast-top"
                        style={{
                            alignSelf: textPosition === 'left' ? 'start' : 'end',
                            padding: textPosition === 'left' ? '10px 10px 10px 20px' : '10px 20px 10px 10px',
                            zIndex: 1,
                            marginLeft: type === 'herrajes' ? '10%' : undefined,
                            position: 'absolute',
                            bottom: '7%'
                        }}
                    >
                        {textPosition === 'right' && <img src={require(`../images/arrow-curve-left.png`)} alt="flecha" />}
                        Mas info
                        {textPosition === 'left' && <img src={require(`../images/arrow-curve-right.png`)} alt="flecha" />}

                    </MoreInfoButton> */}

                    {
                        type === 'herrajes' && (

                            <WaveDiv>
                                <svg viewBox="0 0 500 150" preserveAspectRatio="none">
                                    <path
                                        className="wave"
                                        d="M0,50 C150,150 350,0 500,50 C650,100 850,0 1000,50 L1000,150 L0,150 Z"
                                    />
                                </svg>
                            </WaveDiv>

                        )
                    }

                </Card>

                {(textPosition === 'left' && type !== 'herrajes') && (
                    <Abertura03
                        src={require('../images/perfileria.png')}
                        alt="abertura"
                    />
                )}

                {type === 'herrajes' && (

                    <>

                        <Abertura04
                            src={require('../images/roto-logo.png')}
                            alt="abertura"
                        />

                        <Abertura05
                            src={require('../images/german-made.png')}
                            alt="abertura"
                        />

                        <Abertura06
                            src={require('../images/cremona.png')}
                            alt="abertura"
                        />

                        <Abertura07
                            src={require('../images/multipunto.png')}
                            alt="abertura"
                        />

                        <Abertura08
                            src={require('../images/roto-nx.png')}
                            alt="abertura"
                        />

                    </>

                )}

            </InfoContainer>

            {type === 'herrajes' && (

                <HerrajesIntro />

            )}
        </>

    )
}

const ProductItem = memo(ProductItemComponent);

export default ProductItem;